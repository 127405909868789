/* Reset some basic styles */
body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Set a global box-sizing rule */
  * {
    box-sizing: border-box;
  }
  
  /* Apply some basic styles to the body */
  body {
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
  }
  
  /* Style the main container */
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Center text in h4 and apply some padding */
  h4 {
    text-align: center;
    padding: 20px 0;
  }
  
  /* Add some padding to form fields */
  .MuiTextField-root {
    margin-bottom: 15px;
  }
  
  /* Style the buttons */
  .MuiButton-root {
    margin-top: 10px;
  }
  
  /* Style the file input */
  input[type="file"] {
    margin: 15px 0;
  }
  
  /* Add some spacing for form steps */
  form {
    padding: 20px 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  